$halfHeight: 0.52em;
$borderRadius: 0.15em;

.flip-countdown {
    
    text-align: center;
    perspective: 400px;
    margin: 0 auto;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --background-image: url('../../assets/paper-pattern.png');
    --background-bottom-color: #1b1b1b;
    --text-color: #1b1b1b;
    --text-bottom-color: #1b1b1b;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    .flip-countdown-piece {
        display: inline-block;
        margin: 0 auto;

        .flip-countdown-title {
            font-size: 2rem;
            font-weight: 500;
        }

        .flip-countdown-card {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .flip-countdown-card-sec {
                display: block;
                position: relative;
                margin: 0.5rem;
                padding-bottom: $halfHeight;
                font-size: 5rem;
                line-height: 0.95;
                border-radius: $borderRadius;
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
                    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);

                .card__top,
                .card__bottom,
                .card__back::before,
                .card__back::after {
                    display: block;
                    height: $halfHeight;
                    color: var(--text-color);
                    background: var(--background-image);
                    padding: 0 0.1em;
                    border-radius: $borderRadius $borderRadius 0 0;
                    transform-style: preserve-3d;
                    transform: translateZ(0);
                }

                .card__top {
                    border-bottom: solid 1px #000;
                }

                .card__bottom {
                    color: var(--text-bottom-color);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    background: var(--background-bottom-color);
                    border-radius: 0 0 $borderRadius $borderRadius;
                    pointer-events: none;
                    overflow: hidden;

                    &::after {
                        display: block;
                        margin-top: -$halfHeight;
                        content: attr(data-value);
                    }
                }

                .card__back {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    left: 0%;
                    pointer-events: none;

                    &::before {
                        position: relative;
                        content: attr(data-value);
                        z-index: -1;
                        overflow: hidden;
                    }
                }

                &.flip {
                    .card__back {
                        .card__bottom {
                            transform-origin: center top;
                            animation-fill-mode: both;
                            animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
                        }

                        &::before {
                            animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
                            animation-fill-mode: both;
                            transform-origin: center bottom;
                        }
                    }
                }
            }
        }
    }

    .flip-countdown-card-divider {
        font-size: 2vw;
    }

    // Themes
    &.theme-light {
        --text-color: #1b1b1b;
        --text-bottom-color: #1b1b1b;
        --background-image: url('../../assets/paper-pattern.png');
        --background-repeat: no-repeat;
        --background-bottom-color: #ffffff;
    }

    // Sizes
    &.size-medium {
        .flip-countdown-piece {
            margin: 0 0.2em;

            .flip-countdown-title {
                font-size: 2rem;
                font-weight: 500;
            }

            .flip-countdown-card {
                .flip-countdown-card-sec {
                    margin: 0 0.025em;
                    font-size: 8rem;
                }
            }
        }
    }

    &.size-small {
        .flip-countdown-piece {
            margin: 0 0.15em;

            .flip-countdown-title {
                font-size: 2rem;
                font-weight: 500;
            }

            .flip-countdown-card {
                .flip-countdown-card-sec {
                    margin: 0 0.023em;
                    font-size: 8rem;
                }
            }
        }
    }

    &.size-extra-small {
        .flip-countdown-piece {
            margin: 0 0.15em;

            .flip-countdown-title {
                font-size: 2rem;
                font-weight: 500;
            }

            .flip-countdown-card {
                .flip-countdown-card-sec {
                    margin: 0 0.025em;
                    font-size: 8rem;
                }
            }
        }
    }
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }

    0%,
    99% {
        opacity: 0.99;
    }

    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {

    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg);
        opacity: 0;
    }

    51% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
        transform: rotateX(0deg);
        z-index: 5;
    }
}