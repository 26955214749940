.auth-bg {
	background: url(../public/assets/grid-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: black;
  }
  
  .auth-form {
	background-color: white;
	width: 100%;
	margin-top: 30px;
	border-radius: 10px;
	border: 1px solid black;
	min-height: 300px;
	max-height: calc(100vh - 150px);
  }