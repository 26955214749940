@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
}

@font-face {
  font-family: "GimmeDanger";
  src: url("assets/fonts/GimmeDanger.ttf");
}

@font-face {
  font-family: "DM-Sans";
  src: url("assets/fonts/DMSans-Regular.ttf");
}

.street-text {
  font-family: Aeonik;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
}

.line-height {
  line-height: 2.3;
}

.circle {
  height: 100%;
  aspect-ratio: 1 / 1;
}

@keyframes change-bg {
  0% {
    background-image: url('assets/image-patterened-1.png');
  }

  25% {
    background-image: url('assets/image-patterened-2.png');
  }

  50% {
    background-image: url('assets/image-patterened-3.png');
  }

  75% {
    background-image: url('assets/image-patterened-4.png');
  }

  90% {
    background-image: url('assets/image-patterened-5.png');
  }

  100% {
    background-image: url('assets/image-patterened-1.png');
  }

}

/* 
@keyframes change-bg {
  0% {
    fill: orange;
  }

  20% {
    fill: #34935C;
  }

  40% {
    fill: #d96f49;
  }

  60% {
    fill: blue
  }

  80% {
    fill: #BA3C40
  }

  90% {
    fill: #9C468E
  }

  100% {
    fill: orange
  }

} */

.phones {
  animation: MoveUpDown 4s linear infinite;
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top {
  border-radius: 0.065em 0.065em 0 0;
  color: #1b1b1b !important;
  background-image: url(assets/paper-pattern.png) !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
  border-radius: 0 0 0.065em 0.065em;
  color: #1b1b1b !important;
  background-image: url(assets/paper-pattern.png) !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before {
  border-radius: 0.065em 0.065em 0 0;
  color: #1b1b1b !important;
  background-image: url(assets/paper-pattern.png) !important;
}

.flip-countdown.theme-light {
  --text-color: #1b1b1b !important;
  --text-bottom-color: #1b1b1b !important;
}

.flip-countdown-title {
  background-image: url(assets/paper-pattern.png) !important;
  width: 100%;
}

.flip-countdown .flip-countdown-piece {
  display: inline-block;
  margin: 0 0.6em;
}


.flip-countdown .flip-countdown-piece .flip-countdown-title {
  font-size: 2vw;
  display: block;
  border-radius: 0.1em;
  width: 100%;
  margin: 20px auto;
}

.ml-0 {
  margin-left: 0px !important;
}

.phone-section {
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes LeftRightShadow {
  0% {
    width: 80px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
  }

  50% {
    width: 200px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
  }
}

@keyframes ellipse {
  0% {
    clip-path: ellipse(30% 25%);
    -webkit-filter: blur(10px);
  }

  50% {
    clip-path: ellipse(20% 30%);
    -webkit-filter: blur(10px);
  }

  100% {
    clip-path: ellipse(30% 25%);
    -webkit-filter: blur(10px);
  }
}

.phone-shadow {
  animation: ellipse 4s linear infinite;
  position: relative;
  top: 25px;
}

.absolute-phone {
  position: absolute;
  right: 30px;
  bottom: 26px;
}

.waitlist-button:hover svg {
  -webkit-animation: drop .5s 1 alternate ease-out forwards;
  -moz-animation: drop .5s 1 alternate ease-out forwards;
  animation: drop .5s 1 alternate ease-out forwards;
}

@-webkit-keyframes drop {
  100% {
    -webkit-transform: rotate(90deg);
  }
}

@-moz-keyframes drop {
  100% {
    -moz-transform: rotate(90deg);
  }
}

@keyframes drop {
  100% {
    transform: rotate(90deg);
  }
}

.waitlist-button:hover {
  animation: change-color-move .5s 1 alternate ease-out forwards;
}

@keyframes change-color-move {
  0% {
    background-color: #fff;

  }

  100% {
    background-color: #D87C3E;

  }

}

.waitlist-button:hover svg path {
  animation: change-stroke .5s 1 alternate ease-out forwards;
}

@keyframes change-stroke {


  0% {
    stroke: #1B1B1B;
  }

  50% {
    stroke: #ffffff;
  }

  100% {
    stroke: #ffffff;
  }
}

body {
  background-color: #1B1B1B;
  width: fit-content;
  height: fit-content;
}

.mt--5 {
  margin-top: -5px;
}

.flip-countdown .flip-countdown-piece {
  display: inline-block;
  margin: 0 0.6rem;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
  display: flex;
  position: relative;
  margin: 0 0.04em;
  padding-bottom: 0.52em;
  font-size: 3rem;
  line-height: 0.95;
  border-radius: 0.15em;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%), inset 2px 4px 0 0 rgb(255 255 255 / 8%);
}

.flip-countdown .flip-countdown-piece .flip-countdown-title {
  font-size: 1rem;
  display: block;
  border-radius: 0.1em;
  width: 100%;
  margin: 20px auto;
}


.full-width {
  width: 100%;
}

#hero-bg-svg {
  min-width: 100%;
  /* Scale the SVG to fill the width of its container */
  height: auto;
  /* Maintain the aspect ratio */
}

.hero-bg-wrapper {
  min-width: 100%;
  /* Set the desired width for the SVG container */
}

.hero-content {
  position: relative;

}

.podium {
  position: relative;
}

#root {
  width: 100vw;
}

@media (max-width: 420px) {
  .hero-bg-svg {
    background-color: #5C60AA;
    min-height: 1100px;
    max-height: 1100px;
  }

  .hero-content {
    height: 1020px;
    text-align: center;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 6rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }

  .phone-background-ellipse {
    height: 400px;
    width: 400px;
    position: absolute;
    background-image: url("assets/phone-background-ellipse.png");
    background-size: contain;
    bottom: -50px;
    right: calc(3vw);
    background-repeat: no-repeat;
  }

  .text-shadow {
    text-shadow: 7px 7px #1B1B1B;
  }

  .drop-shadow {
    box-shadow: 4px 4px 0 #1b1b1b;
  }
}

@media (min-width: 420px) and (max-width: 640px) {
  .hero-bg-svg {
    background-image: url(assets/mobile1.svg);
    background-repeat: no-repeat;
    background-size: 99%;
    min-height: 1000px;
    max-height: 1200px;
  }

  .hero-content {
    height: 1000px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 6rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }

  .phone-background-ellipse {
    height: 500px;
    width: 500px;
    position: absolute;
    background-image: url("assets/phone-background-ellipse.png");
    background-size: contain;
    bottom: -50px;
    right: calc(-4vw);
    background-repeat: no-repeat;
  }

  .text-shadow {
    text-shadow: 7px 7px #1B1B1B;
  }

  .drop-shadow {
    box-shadow: 4px 4px 0 #1b1b1b;
  }
}

@media (min-width: 640px) {
  .hero-bg-svg {
    background-size: 100% 420px;
    min-height: 420px;
    max-height: 420px;
  }

  .hero-content {
    height: 372px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 4.5rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }

  .hero-bg-svg {
    /* background-image: url(assets/image1.svg);
    background-repeat: no-repeat; */
    background-size: 100% 420px;
    min-height: 420px;
    max-height: 420px;
  }

  .phone-background-ellipse {
    height: 300px;
    width: 300px;
    position: absolute;
    background-image: url("assets/phone-background-ellipse.png");
    background-size: contain;
    bottom: -20px;
    right: calc(2vw);
    background-repeat: no-repeat;
  }

}

@media (min-width: 768px) {
  .hero-bg-svg {
    background-size: 100% 500px;
    min-height: 500px;
    max-height: 500px;
  }

  .hero-content {
    height: 452px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 4.5rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }

  .phone-background-ellipse {
    height: 400px;
    width: 400px;
    position: absolute;
    background-image: url("assets/phone-background-ellipse.png");
    background-size: contain;
    bottom: -50px;
    right: calc(1vw);
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) {
  #root {
    width: 99vw;
  }

  .hero-bg-svg {
    background-size: 100% 695px;
    min-height: 695px;
    max-height: 695px;
  }

  .hero-content {
    height: 615px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 5rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }

  .phone-background-ellipse {
    height: 600px;
    width: 600px;
    position: absolute;
    background-image: url("assets/phone-background-ellipse.png");
    background-size: contain;
    bottom: -100px;
    right: -50px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) {
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 7rem;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 2rem;
  }
}


.flip-countdown.theme-light {
  --text-color: #1b1b1b;
  --text-bottom-color: #1b1b1b;
  --background-color: none;
  --background-bottom-color: none;
}

.preload {
  display: none
}

.center-block {
  margin: 0 auto;
  float: none;
}

.loading {
  background: #595959;
}

.grid-bg {
  background: url(assets/grid-bg.svg);
}





@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;700;800&display=swap');



.sora-subtitle {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 167.8%;
  display: flex;
  align-items: center;
  color: #858585;
}


.sora-heading {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 125.64%;
  color: #FFFFFF;
  text-shadow: 7px 7px 0px #1B1B1B;
}

.sora-heading-md {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 125.64%;
  color: #FFFFFF;
  text-shadow: 7px 7px 0px #1B1B1B;
  padding-top: 45px;
  padding-bottom: 10px;
}
.sora-heading-sm {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 125.64%;
  color: #FFFFFF;
  text-shadow: 7px 7px 0px #1B1B1B;
  padding-top: 45px;
  padding-bottom: 10px;
}

.sora-heading-xs {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 125.64%;
  color: #FFFFFF;
  text-shadow: 7px 7px 0px #1B1B1B;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 25px;
}

.sora-regular {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding-bottom: 25px;
}

.sora-regular ul {
  list-style-type: circle;
  padding-left: 50px;
}

.sora-regular ol {
  list-style-type: dot;
  padding-left: 30px;
}

.sora-regular ol li {
  padding-left: 10px;
}